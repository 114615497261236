import { BoxProps } from '@mui/material';
import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { TransactionResponse } from '@ethersproject/providers';
import { queryClient } from '../../../../pages/_app.page';
import { useModalContext } from '../../../hooks/useModal';
import { useWeb3Context } from '../../../libs/hooks/useWeb3Context';
import { useRootStore } from '../../../store/root';
import { getErrorTextFromError, TxAction } from '../../../ui-config/errorMapping';
import { QueryKeys } from '../../../ui-config/queries';
import { useRequestWithFlashloanProvider } from '../../../hooks/yldr/useRequestWithFlashloanProvider';
import { TxPositionActionsWrapper } from '../TxPositionActionsWrapper';

export interface DeleveragePositionActionsProps extends BoxProps {
  marketId?: number;
  positionAddress?: string;
  isWrongNetwork: boolean;
  noFees?: boolean;
  feesCollected: number;
  disabled?: boolean;
}

export const ClaimFeesActions = React.memo(
  ({ marketId, isWrongNetwork, positionAddress, noFees, feesCollected, disabled }: DeleveragePositionActionsProps) => {
    const requestWithFlashloanProvider = useRequestWithFlashloanProvider();
    const { mainTxState, setMainTxState, setTxError, setGasLimit } = useModalContext();
    const { sendTx } = useWeb3Context();

    const [claimFees] = useRootStore((state) => [state.claimFees]);

    const action = async () => {
      if (!marketId || !positionAddress || mainTxState.success) return;

      try {
        window.gtag('event', 'leverage_modal_claim_fees');

        setTxError(undefined);
        setMainTxState({ ...mainTxState, loading: true });

        const compoundTxData = await requestWithFlashloanProvider({
          fn: claimFees,
          args: [marketId, positionAddress],
        });

        const response: TransactionResponse = await sendTx(compoundTxData);
        await response.wait(1);

        const invalidates = [
          queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS] }),
          queryClient.invalidateQueries({ queryKey: [QueryKeys.POSITIONS_DATA] }),
          queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS] }),
          queryClient.invalidateQueries({ queryKey: [QueryKeys.LEVERAGED_POSITIONS_DATA] }),
        ]

        await Promise.all(invalidates);

        setMainTxState({
          txHash: response.hash,
          loading: false,
          success: true,
          value: feesCollected.toString(),
        });

      } catch (error) {
        const parsedError = getErrorTextFromError(error, TxAction.GAS_ESTIMATION, false);
        setTxError(parsedError);
        setMainTxState({
          txHash: undefined,
          loading: false,
        });
      }
    };

    // Update gas estimation
    useEffect(() => {
      if (!marketId || !positionAddress || mainTxState.success) return;

      const calculateGasLimit = async () => {
        const compoundTxData = await requestWithFlashloanProvider({
          fn: claimFees,
          args: [marketId, positionAddress],
        });
        setGasLimit(compoundTxData.gasLimit?.toString() || '0');
      }

      const timerId = setTimeout(calculateGasLimit, 500);
      const intervalId = setInterval(calculateGasLimit, 15000);
      return () => {
        clearInterval(intervalId);
        clearTimeout(timerId);
      }
    }, [mainTxState.success]);

    return (
      <TxPositionActionsWrapper
        mainTxState={mainTxState}
        isWrongNetwork={isWrongNetwork}
        requiresAmount
        preparingTransactions={false}
        actionText={<Trans>Claim Fees</Trans>}
        actionInProgressText={<Trans>Claiming Fees</Trans>}
        handleAction={action}
        noFees={noFees}
        disabled={disabled}
      />
    );
  }
);
